<template>
  <aside class="sidebar">
    <div class="flex justify-between">
      <router-link class="flex" to="/">
        <img
            class="h-11"
            alt="Travel Afrique Logo"
            src="@/assets/img/logo.svg"
        />
      </router-link>
      <ButtonClose class="btn-mb" @click.native="$emit('closeMenu')"/>
    </div>
    <nav class="pt-10">
      <SidebarButton
          v-if="false"
          text="Dashboard"
          route="/"
          iconClass="bx bxs-chart"
          class="mb-3"
      />
      <SidebarButton
          text="Requests"
          route="/"
          iconClass="bx bxs-spreadsheet"
          class="mb-3"
      />
      <SidebarButton
          text="Company"
          route="/company"
          iconClass="bx bxs-buildings"
          class="mb-3"
      />
      <SidebarButton
          text="My account"
          route="/account"
          iconClass="bx bxs-user-circle"
          class="mb-3"
      />
      <ButtonIconText
          @click.native.prevent="submitLogout"
          text="Logout"
          class="w-full h-12 mt-20"
          iconClass="bx bx-log-out"
      />
    </nav>
  </aside>
</template>
<script>
import ButtonIconText from "@/components/Buttons/ButtonIconText";
import ButtonClose from "@/components/Buttons/ButtonClose";
import SidebarButton from "@/components/Layout/Sidebar/SidebarButton";
import {mapActions} from "vuex";

export default {
  name: "Sidebar",
  components: {
    ButtonClose,
    ButtonIconText,
    SidebarButton,
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
    async submitLogout() {
      this.logout()
          .then(() => {
            this.$router.push({name: "login"});
          })
          .catch(() => {
            this.$router.push({name: "login"});
          });
    },
  },
};
</script>
<style scoped>
.sidebar {
  @apply h-full block bg-white w-72 px-4 sm:px-6 py-8;
  @apply hidden md:block lg:left-0 lg:shadow-none lg:relative;
}

.btn-mb {
  @apply block md:hidden;
}
</style>