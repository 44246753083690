<template>
  <nav class="navbar">
    <BackgroundLayer
      class="bg-mb"
      v-show="showBgLayer"
      @click.native="closeMenu"
    />
    <Sidebar :class="['sidebar-mb', sidebarPosition]" @closeMenu="closeMenu" />
    <div class="nav-wrapper">
      <button
        type="button"
        @click="openMenu"
        class="btn-menu"
        v-if="!showBackBtn"
      >
        <i class="bx bx-menu bx-sm"></i>
      </button>

      <ButtonIconTextFrameless
        @click.native="goBack"
        iconClass="bx bx-arrow-back bx-sm"
        text="BACK"
        v-if="showBackBtn"
        responsive="horizontal"
      />
      <div class="search-wrapper">
        <span class="absolute inset-y-0 left-0 flex items-center pl-2">
          <button type="submit" class="button">
            <i class="bx bx-search bx-xs"></i>
          </button>
        </span>
        <input
          type="search"
          name="search"
          class="search"
          placeholder="Customer Name · Reference Number"
          autocomplete="off"
        />
      </div>
      <div class="logged-in flex items-center">
        <!--        <template v-if="!hasCustomButtonSlot">-->
        <!--          <ButtonLink text="Create an offer" route="/request/offer" />-->
        <!--        </template>-->
        <slot name="button"></slot>
      </div>
    </div>
  </nav>
</template>
<script>
import BackgroundLayer from "@/components/Layout/BackgroundLayer";
import ButtonIconTextFrameless from "@/components/Buttons/ButtonIconTextFrameless";
import Sidebar from "@/components/Layout/Sidebar/Sidebar";

export default {
  components: { BackgroundLayer, ButtonIconTextFrameless, Sidebar },
  props: {
    showBackBtn: {
      type: Boolean,
      default: false,
    },
    route: { type: String, default: "/requests" },
  },
  name: "Navbar",
  data() {
    return {
      showBgLayer: false,
      sidebarPosition: "sidebar-mb-lf",
    };
  },
  created() {
    window.addEventListener("resize", this.elemViewportReset);
    document.addEventListener("click", this.toggleBodyOverflow);
  },
  destroyed() {
    window.addEventListener("resize", this.elemViewportReset);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
      //this.$router.push(this.route);
    },
    openMenu() {
      this.sidebarPosition = "sidebar-mb-lz";
      this.showBgLayer = true;
    },
    closeMenu() {
      this.sidebarPosition = "sidebar-mb-lf";
      this.showBgLayer = false;
    },
    elemViewportReset() {
      //If viewport width is equal to or bigger than 1024,, then hide mobile nav
      if (window.innerWidth >= 1024) {
        this.showBgLayer = false;
        this.sidebarPosition = "sidebar-mb-lf";
      }
    },
  },
  computed: {
    hasCustomButtonSlot() {
      return !!this.$slots.button;
    },
  },
};
</script>
<style scoped>
.navbar {
  @apply z-50 flex w-full flex-wrap items-center px-4 md:px-12 py-4;
}
.nav-wrapper {
  @apply relative transform left-1/2 -translate-x-1/2 max-w-screen-xxl w-full flex items-center justify-between;
}
.search-wrapper {
  @apply relative text-black-base mx-auto w-3/4 sm:mr-auto md:ml-0 lg:w-80 hidden;
}
.sidebar-mb {
  @apply fixed top-0 shadow-lg z-40 w-80vw xs:w-70vw sm:w-50vw block md:hidden;
  @apply transition-all ease-in-out duration-700;
}
.sidebar-mb-lf {
  @apply -left-full;
}
.sidebar-mb-lz {
  @apply left-0;
}
.search {
  @apply py-2 text-sm w-full bg-white border border-grey-dark focus:outline-none focus:ring-teal-base focus:border-teal-light rounded-md pl-10 focus:bg-white focus:text-black-base;
}
.btn-menu {
  @apply p-1 md:hidden focus:outline-none text-2xl text-black-base;
}

.bg-mb {
  @apply fixed z-30 w-100vw left-0 top-0 h-100vh;
}
</style>