<template>
  <div>
    <div class="row-item">
      <div class="text-container">
        <div class="flex justify-between items-center">
          <h4 class="font-sans font-semibold text-black-base mb-1">Name</h4>

          <button
              v-if="!showNameEdit"
              class="btn"
              type="button"
              @click="editName"
          >
            Edit
          </button>
          <button
              v-if="showNameEdit"
              class="btn"
              type="button"
              @click="cancelEditName"
          >
            Cancel
          </button>
        </div>

        <p v-if="!showNameEdit" class="font-sans text-black-lighter text-sm">
          {{ userDetails.name }}
        </p>
        <div>
          <InputText
              v-if="showNameEdit"
              v-model="userDetails.name"
              :showLabel="false"
              class="w-full mt-2"
              name="name"
              placeholder="e.g. Tracy Doe"
              type="text"
          />
          <slot></slot>
          <ButtonPrimary v-if="showNameEdit" class="px-8 mt-4" text="Save"
                         @click.native="submitUpdateAccountDetails('name')"/>
        </div>
      </div>
    </div>

    <div class="row-item">
      <div class="text-container">
        <div class="flex justify-between items-center">
          <h4 class="font-sans font-semibold text-black-base mb-1">Email</h4>

          <button
              v-if="!showMailEdit"
              class="btn"
              type="button"
              @click="editMail"
          >
            Edit
          </button>
          <button
              v-if="showMailEdit"
              class="btn"
              type="button"
              @click="cancelEditMail"
          >
            Cancel
          </button>
        </div>

        <p v-if="!showMailEdit" class="font-sans text-black-lighter text-sm">
          {{ userDetails.email }}
        </p>
        <div>
          <InputText
              v-if="showMailEdit"
              v-model="userDetails.email"
              :showLabel="false"
              class="w-full mt-2"
              name="email"
              placeholder="e.g. john@example.com"
              type="email"
          />
          <ButtonPrimary v-if="showMailEdit" class="px-8 mt-4" text="Save"
                         @click.native="submitUpdateAccountDetails('email')"/>
        </div>
      </div>
    </div>


    <div :class="['row-item']">

      <div class="text-container">
        <div class="flex justify-between items-center">
          <h4 class="font-sans font-semibold text-black-base mb-1">Phone</h4>
          <button
              v-if="!showPhoneEdit"
              class="btn"
              type="button"
              @click="editPhone"
          >
            Edit
          </button>
          <button
              v-if="showPhoneEdit"
              class="btn"
              type="button"
              @click="cancelEditPhone"
          >
            Cancel
          </button>
        </div>
        <p
            v-if="!showPhoneEdit"
            class="font-sans text-black-lighter text-sm"
        >
          {{ userDetails.phone }}
        </p>
        <div>
          <InputPhone
              v-if="showPhoneEdit"
              v-model="userDetails.phone"
              :phoneNumber="userDetails.phone"
              :showLabel="false"
              class="mt-2"
              @getObj="updateNumberObj($event)"
              @input="updateNumber($event)"
              @sliceZeros="updateNumber($event)"
          />
          <ButtonPrimary
              v-if="showPhoneEdit"
              class="px-8 mt-4"
              text="Save"
              @click.native="submitUpdateAccountDetails('phone')"
          />
        </div>
      </div>
    </div>

    <div :class="['row-item']">
      <div class="text-container">
        <div class="flex justify-between items-center">
          <h4 class="font-sans font-semibold text-black-base mb-1">Password</h4>

          <button
              v-if="!showPasswordEdit"
              class="btn"
              type="button"
              @click="editPassword"
          >
            Edit
          </button>
          <button
              v-if="showPasswordEdit"
              class="btn"
              type="button"
              @click="cancelEditPassword"
          >
            Cancel
          </button>
        </div>
        <p
            v-if="!showPasswordEdit"
            class="font-sans text-black-lighter text-sm"
        >
          *********
        </p>
        <div>

          <div v-if="showPasswordEdit">
            <InputText
                v-model="userDetails.password"
                :showLabel="false"
                class="w-full mt-2"
                name="password"
                placeholder="Password"
                type="password"
            />
          </div>

          <ButtonPrimary
              v-if="showPasswordEdit"
              class="px-8 mt-4"
              text="Save"
          />
        </div>
      </div>
    </div>


  </div>
</template>
<script>
import ButtonPrimary from "@/components/Buttons/ButtonPrimary";
import InputText from "@/components/Inputs/InputText";
import {mapActions, mapGetters} from "vuex";
import InputPhone from "@/components/Inputs/InputPhone";

export default {
  name: "SettingsTable",
  data() {
    return {
      showMailEdit: false,
      showPasswordEdit: false,
      showPhoneEdit: false,
      showNameEdit: false,
      userDetails: {},
    };
  },
  components: {
    InputPhone,
    InputText,
    ButtonPrimary,
  },
  methods: {
    ...mapActions({
      getAccountDetails: "auth/getAccountDetails",
      updateAccountDetails: "auth/updateAccountDetails"
    }),
    updateNumberObj(obj) {
      this.userDetails.phone = obj.dial_code + obj.phone;
    },
    updateNumber(value) {
      this.userDetails.phone = value.dial_code + value.phone;
    },
    editMail() {
      this.showMailEdit = true;
    },
    cancelEditMail() {
      this.showMailEdit = false;
    },
    cancelEditPhone() {
      this.showPhoneEdit = false;
    },
    cancelEditName() {
      this.showNameEdit = false;
    },
    editPassword() {
      this.showPasswordEdit = true;
    },
    cancelEditPassword() {
      this.showPasswordEdit = false;
    },
    editName() {
      this.showNameEdit = true;
    },
    editPhone() {
      this.showPhoneEdit = true;
    },
    submitUpdateAccountDetails(type) {
      let data = {};

      if (type === 'email') {
        data = {
          email: this.userDetails.email
        }
      }
      if (type === 'phone') {
        data = {
          phone: this.userDetails.phone,
        }
      }
      if (type === 'name') {
        data = {
          name: this.userDetails.name
        }
      }
      if (type === 'password') {
        data = {
          password: this.userDetails.password
        }
      }
      this.updateAccountDetails(data).then(({data}) => {

        this.userDetails = {...data.user};
        this.showMailEdit = false;
        this.showPasswordEdit = false;
        this.showPhoneEdit = false;
        this.showNameEdit = false;

        this.$notify({
          type: "success",
          title: `Successful`,
          text: data.message,
        });
      })
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },

  mounted() {
    this.getAccountDetails().then(({data}) => {

      this.userDetails = {...data.user};
    })

  },
  props: {
    props: {
      style: String,
      default: "opacity-5",
    },
    email: {
      type: String,
      default: "tracy@travelafrique.com",
    },
  },
};
</script>
<style scoped>
.row-item {
  @apply w-full border-b border-grey-base py-8;
}

.btn {
  @apply font-sans text-teal-base focus:outline-none duration-300 transition hover:underline;
}
</style>