<template>
  <div class="w-full">
    <label v-if="showLabel" class="phone-label" for="phone-number">
      Phone number
    </label>
    <div class="input-select-container">
      <!-- English -->
      <select
          v-if="lang === 'en'"
          v-model="selected"
          :readonly="readonlyAuth"
          name="dial-code"
          @change="updateNumberObj"
      >
        <option
            v-for="(number, index) in numbersEn"
            :key="index"
            :value="JSON.stringify(number)"
        >
          {{ number.en }} ({{ number.dial_code }})
        </option>
      </select>

      <!-- French -->
      <select
          v-if="lang === 'fr'"
          v-model="selected"
          :readonly="readonlyAuth"
          name="dial-code"
          @change="updateNumberObj"
      >
        <option
            v-for="(number, index) in numbersFr"
            :key="index"
            :value="JSON.stringify(number)"
        >
          {{ number.fr }} ({{ number.dial_code }})
        </option>
      </select>

      <div class="input-container">
        <span>{{ code }}</span>
        <input
            id="phone-number"
            :class="[showErrMsg ? 'border-red-base' : 'border-grey-dark']"
            :value="phoneNr"
            type="number"
            @blur="$emit('blur'); checkIfFirsDigitIsZero($event)"
            @input="updateNumber($event)"
            @keypress="isNumeric($event)"
        />
      </div>
    </div>
    <span v-if="showErrMsg" class="error-msg">
      {{ errorMsg }}
    </span>
  </div>
</template>
<script>
import phone from "@/data/phonecodes/phone.json";
import validator from "@/data/phonecodes/validator.json";

export default {
  name: "InputPhone",
  inheritAttrs: false,
  props: {
    showLabel: {
      type: Boolean,
      default: true,
    },
    errorMsg: {
      type: String,
      default: "",
    },
    showErrMsg: {
      type: Boolean,
      default: false,
    },
    readonlyAuth: {
      type: Boolean,
      default: false,
    },
    phoneNumber: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      phoneNr: this.getPhoneNumberWithoutCode(),
      lang: localStorage.getItem("lang") || "en",
      selected: this.getCountryObject(),
    };
  },
  computed: {
    //Sort phone json file alphabetically by English names
    numbersEn() {
      return phone.sort((a, b) => {
        return a.en.localeCompare(b.en);
      });
    },

    //Sort phone json file alphabetically by French names
    numbersFr() {
      return phone.sort((a, b) => {
        return a.fr.localeCompare(b.fr);
      });
    },

    //Get dial code to be displayed in the component
    code() {
      let obj = JSON.parse(this.selected);
      return obj.dial_code;
    },
  },
  methods: {
    updateNumber(event) {
      this.phoneNr = event.target.value;
      let countryObj = JSON.parse(this.selected);
      this.$emit('input', {phone: event.target.value, dial_code: countryObj.dial_code});
    },
    //Only allow to type numeric characters
    isNumeric(event) {
      let char = String.fromCharCode(event.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true;
      // Match with regex
      else event.preventDefault(); // If not match, don't add to input text
    },
    //Update select value
    updateNumberObj(event) {
      let object = JSON.parse(event.currentTarget.value);
      this.selected = event.target.value;
      this.$emit("getObj", {dial_code: object.dial_code, code: object.code, phone: this.phoneNr});
    },
    //Remove all spaces and other unexpected formatting
    cleanNumberFormat(number) {
      number = number.replace("|", ""); //Remove all pipes
      number = number.replace(" ", ""); //Remove all whitespace
      number = number.replace("(", ""); //Remove Parenthesis
      number = number.replace(")", "");//Remove Parenthesis
      return number;
    },

    //Split phone code from phone number
    getPhoneNumberWithoutCode() {
      let number = this.cleanNumberFormat(this.phoneNumber);
      let phoneNumber;
      let code;

      if (/^[A-Za-z]{2}/.test(number)) {
        phoneNumber = number.slice(2);
      }

      if (number[0] === "+") {
        if (validator.some(country => country.dial_code === number.slice(0, 5))) {
          code = validator.find(country => country.dial_code === number.slice(0, 5));
          code = this.checkIfCodeException(code);
        } else if (validator.some(country => country.dial_code === number.slice(0, 4))) {
          code = validator.find(country => country.dial_code === number.slice(0, 4));
          code = this.checkIfCodeException(code);
        } else if (validator.some(country => country.dial_code === number.slice(0, 3))) {
          code = validator.find(country => country.dial_code === number.slice(0, 3));
          code = this.checkIfCodeException(code);
        } else if (validator.some(country => country.dial_code === number.slice(0, 2))) {
          code = validator.find(country => country.dial_code === number.slice(0, 2));
          code = this.checkIfCodeException(code);
        }

        phoneNumber = number.slice(code.length);
      }

      if (number[0] !== "+" && !/^[A-Za-z]{2}/.test(number)) {
        phoneNumber = number;
      }
      return phoneNumber;
    },

    //Get correct json object from json file
    getCountryObject() {
      let number = this.cleanNumberFormat(this.phoneNumber);
      let obj;
      if (/^[A-Za-z]{2}/.test(number)) {
        if (validator.some(country => country.code === number.slice(0, 2))) {

          obj = validator.find(country => country.code === number.slice(0, 2));
          obj = JSON.stringify(obj);
        }
      }

      if (number[0] === "+") {
        if (validator.some(country => country.dial_code === number.slice(0, 5))) {
          obj = validator.find(country => country.dial_code === number.slice(0, 5));
          obj = this.checkIfObjException(obj);
        } else if (validator.some(country => country.dial_code === number.slice(0, 4))) {
          obj = validator.find(country => country.dial_code === number.slice(0, 4));
          obj = this.checkIfObjException(obj);
        } else if (validator.some(country => country.dial_code === number.slice(0, 3))) {
          obj = validator.find(country => country.dial_code === number.slice(0, 3));
          obj = this.checkIfObjException(obj);
        } else if (validator.some(country => country.dial_code === number.slice(0, 2))) {
          obj = validator.find(country => country.dial_code === number.slice(0, 2));
          obj = this.checkIfObjException(obj);
        }
      }

      if (number[0] !== "+" && !/^[A-Za-z]{2}/.test(number)) {
        obj = '{"en":"Afghanistan","fr":"Afghanistan","dial_code":"+93","code":"AF"}';
      }
      return obj;
    },

    //Checks if the current dial code is an exception (Canada, Dominican Republic, Puerto Rico)
    //and returns correct country code
    checkIfObjException(obj) {
      if (obj.code === "CA") {
        return JSON.stringify({en: "Canada", fr: "Canada", dial_code: "+1", code: "CA"});
      }
      if (obj.code === "DO") {
        return JSON.stringify({en: "Dominican Republic", fr: "République dominicaine", dial_code: "+1", code: "DO"});
      }
      if (obj.code === "PR") {
        return JSON.stringify({en: "Puerto Rico", fr: "Porto Rico", dial_code: "+1", code: "PR"});
      }

      return JSON.stringify(obj);
    },
    checkIfCodeException(code) {
      if (code.code === "CA") {
        return "+1";
      }
      if (code.code === "DO") {
        return "+1";
      }
      if (code.code === "PR") {
        return "+1";
      }

      return code.dial_code;
    },

    //Check if first digit in input field is zero and remove if true is
    checkIfFirsDigitIsZero(event) {
      let number = event.target.value;
      for (let i = 0; i < number.length; i++) {
        if (number[0] === "0") number = number.slice(1);
      }
      this.phoneNr = number;
      let object = JSON.parse(this.selected);
      let dial_code = object.dial_code;
      let code = object.code;
      this.$emit("sliceZeros", {dial_code: dial_code, code: code, phone: number});
      event.target.value = number;
    }
  },
};
</script>

<style scoped>
input,
select {
  @apply leading-tight w-full p-3 border rounded;
  @apply focus:border-teal-base focus:ring-teal-lighter focus:ring-2;
  @apply text-black-base placeholder-black-lightest;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.input-select-container {
  @apply grid grid-cols-12 gap-4 w-full;
}

select,
input {
  @apply border rounded;
}

select {
  @apply border-grey-dark;
}

input {
  @apply pl-20;
}

.phone-label {
  @apply mb-3 block font-semibold text-black-base;
}

.input-select-container select {
  @apply col-span-12 xs:col-span-5;
}

.input-container {
  @apply col-span-12 xs:col-span-7 relative;
}

.input-container span {
  @apply absolute top-1/2 transform -translate-y-1/2 left-3;
  @apply text-black-lightest border-r border-grey-base;
  @apply w-14;
}

.error-msg {
  @apply block mt-2 font-sans text-sm font-medium text-red-base;
}
</style>