<template>
  <router-link :to="route" class="group">
    <i :class="[iconClass, 'bx-sm', 'icon']"></i>
    <span class="text"> {{ text }}</span>
  </router-link>
</template>
<script>
export default {
  name: "SidebarButton",
  props: {
    text: {
      type: String,
      default: "Item",
    },
    iconClass: {
      type: String,
      default: "bx bx-user",
    },
    route: {
      type: String,
      default: "/",
    },
  },
};
</script>

<style scoped>
.group {
  @apply p-3 px-4 w-full block hover:bg-black-base rounded-md focus:ring-offset-2 focus:ring-black-base;
  @apply flex items-center;
}

.icon {
  @apply text-black-base group-hover:text-white;
}

.text {
  @apply text-black-lightest text-sm group-hover:text-white ml-2.5;
}

.exact-active {
  @apply bg-black-base;
}

.exact-active i,
.exact-active span {
  @apply text-white;
}
</style>
