<template>
  <div class="nav-bottom">
    <div class="nav-bottom-wrapper">
      <a href="/" :class="['nav-bottom-item', active.requests]">
        <i class="bx bxs-spreadsheet bx-sm"></i>
        <span class="nav-bottom-item-text">Requests</span>
      </a>
      <a href="/company" :class="['nav-bottom-item', active.company]">
        <i class="bx bxs-buildings bx-sm"></i>
        <span class="nav-bottom-item-text">Company</span>
      </a>
      <a href="/account" :class="['nav-bottom-item', active.account]">
        <i class="bx bxs-user-circle bx-sm"></i>
        <span class="nav-bottom-item-text">My account</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBottom",
  computed: {
    active() {
      let obj = {
        requests: "",
        company: "",
        account: ""
      }
      let pathname = window.location.pathname;

      for (let item in obj) {
        if (pathname.includes(item)) {
          obj[item] = "active";
        } else if (pathname.length === 1) {
          obj.requests = "active";
        } else {
          obj[item] = "";
        }
      }
      return obj;
    }
  }
}
</script>

<style scoped>

.nav-bottom {
  @apply w-full bg-white fixed bottom-0 border-t border-grey-base;
  @apply block md:hidden;
}

.nav-bottom-wrapper {
  @apply max-w-xs w-full left-1/2 transform -translate-x-1/2 relative;
  @apply py-2 flex justify-center text-black-lightest;
}

.nav-bottom-item {
  @apply text-center w-1/3;
}

.nav-bottom-item-text {
  @apply block text-xs;
}

.active i {
  @apply text-teal-base;
}

.active .nav-bottom-item-text {
  @apply text-black-base;
}
</style>